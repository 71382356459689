@import '/src/theme/variables';

.checkbox-container {
    cursor: pointer;
    margin: 1rem 0;
    svg {
        fill: transparent;
        path {
            stroke: $blue;
        }
    }

    .title {
        font-size: 1.4rem;
    }
}
