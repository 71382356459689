$primary: #97DE00;
$secondary: #19b6c9;
$tertiary: #f1c660;
$blue: #488ae8;
$black: #000000;
$white: #ffffff;
$grey: #ebebeb;
$light: #f8f8f8;
$lightBlue: #eaf8fa;
$danger: #bd2a2a;
$light-primary : #EEF0FF;
$light-navy : #D4D6E6;  