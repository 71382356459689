@import '/src/theme/variables';
@import '/src/theme/media';

.title-date-container {
    position: relative; // add position relative to the container
    width: 80%;
    .title {
        font-size: 2.4rem; 
        font-weight: 500;
    }

    .date {
        font-size: 1.4rem; 
        color: rgba($black, 0.6);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 0.1rem; 
        background-color: rgba($blue, 0.2);
    }
}

body[dir='ltr'] {
    .title-date-container {
        width: 80%;
        margin-left: 2rem; 
        padding-left: 2rem; 
        &::before {
            left: 0;
            right: auto;
        }
    }
}

.copy-link-btn {
    svg {
        fill: transparent;
    }

    .btn-title {
        position: relative; // add position relative to the button container

        &::before {
            content: '';
            position: absolute;
            top: 50%; // center the line vertically
            left: 0;
            transform: translateY(-50%); // center the line vertically
            height: 90%;
            width: 0.1rem; 
            background-color: rgba($white, 0.2); // set the background color of the line
        }
    }
}

.header {
    direction: rtl;
}

.lang-btn {
    cursor: pointer;
    svg,
    path {
        fill: $primary;
        stroke: $primary;
    }
}


.lang-list {
    width: 100%;

    li {
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        padding: 1rem;
        margin: 0;

        &:hover {
            background-color: rgba($primary, 0.1);
        
        }
    }
}

.dropdown-container {
    top: 50%;
    transform: translateY(-50%) translateX(-11rem); 
    left : 0;
    width: 15rem; 
    z-index: 999;
}

.lang-dropdown {
    padding: 0 !important;
    display: flex;
    overflow: hidden;
}

body[dir='ltr'] {
    .dropdown-container {
        transform: translateY(-50%) translateX(11rem); 
        right: 0;
        left: auto;
    }
}
