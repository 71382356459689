@import '/src/theme/variables';
@import '/src/theme/media';

.dropdown-item {
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    &:hover {
        color: $blue;
    }
}

.search-input-container {
    position: relative;
    svg {
        fill: transparent;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5%;

        path {
            stroke: $blue;
        }
    }

    .clear-search {
        cursor: pointer;
        svg {

            left: 5%;
            transition: all 0.1s ease-in-out;
            right: auto;
            fill: rgba($black, .5);
            
            &:hover {
                fill: $danger;
            }
        }
    }

    .search-transcript-input {
        padding: 1rem;
        width: 24.7rem;
        background-color: $light;
        padding-right: 3.5rem;
        font-size: 1.2rem;

        &::placeholder {
            color: $black;
        }

        &:focus {
            outline: none;
        }
    }
}

.transcript-list {
    height: 73vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}


.no-result {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba($black, .5);
    font-size: 2rem;
}