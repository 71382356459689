@import '/src/theme/variables';

.expired-heading {
    font-size: 4rem;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $blue;
}
