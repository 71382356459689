
$screen-widths: (
  "xs": 576px,
  "sm": 768px,
  "md": 992px,
  "lg": 1200px,
  "xl": 1366px,
  "xxl": 1920px
);

@mixin responsiveOn($width) {
  @media only screen and  (max-width: map-get($screen-widths, $width)) {
    @content;
  }
}

