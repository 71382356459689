@import '/src/theme/variables';
@import '/src/theme/media';

.dropdown-button {
    background-color: rgba($blue, 0.09);
    padding: 1rem;
    width: 17.4rem;
    font-size: 1.2rem;

    svg {
        fill: transparent;
    }
}

.dropdown-list {
    li {
        margin: 1rem 0;
        font-size: 1.4rem;
    }
}


