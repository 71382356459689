@import '/src/theme/variables';
@import '/src/theme/media';

.questions-container {
    border-top: 0.1rem solid rgba($blue, 0.2);

    .title {
        font-weight: bold;
        color: $primary;
        margin-inline-end: 1rem;
    }

    .questions-list {
        gap: 2rem;
    }

    .question-item {
        background-color: $lightBlue;
        padding: 0.5rem 2rem;
        border-radius: 5rem;
        transition: all 0.1s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
}
