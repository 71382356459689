@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "./theme/fonts";
@import "./theme/media";
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');


html {
    font-size: 62.5%;
}

* {
    font-size: 1.6rem;
}

@include responsiveOn('xxl') {
    html {
        font-size: 62.5%;
    }
}

@include responsiveOn('xl') {
    html {
        font-size: 50%;
    }
}

@include responsiveOn('lg') {
    html {
        font-size: 45%;
    }
}

@include responsiveOn('md') {
    html {
        font-size: 40%;
    }
}

@include responsiveOn('sm') {
    html {
        font-size: 35%;
    }
}


@include responsiveOn('xs') {
    html {
        font-size: 30%;
    }
}




body {
    margin: 0;
    font-family: 'AmpleSoftPro';
    font-weight: 400;
}

body[dir='rtl'] {
    font-family:  Tajawal;
    font-weight: 400;
}
