@import '/src/theme/variables';
@import '/src/theme/media';

.meeting-info {
    height: 100%;
    .tabs-container {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 2rem;
        border-bottom: 0.1rem solid rgba($blue, 0.2);
    }

    .tab-item {
        padding: 1rem;
        margin-right: 1rem;
        border-radius: 0.5rem;
        cursor: pointer;
        width: 50%;
        display: block;
        text-align: center;
        cursor: pointer;

        &.active {
            color: $white;
            background-color: $primary;
        }
    }

    .tab-content {
        height: calc(100% - 2rem) !important;
        overflow-y: auto;
    }
}


