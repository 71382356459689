.no-scroll::-webkit-scrollbar {
    display: none;
}

video {
    border-radius: 7px;
}

.quicksand {
    font-family: 'Quicksand', sans-serif;
}

.audio-container div:first-child {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
