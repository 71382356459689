@import '/src/theme/variables';
@import '/src/theme/media';

.meeting-container {
    display: flex;
    height: 100vh;
    overflow-y: hidden;
    flex-direction: row-reverse;

    .video-speech-container {
        padding: 2rem;
    }

    .meeting-info-container {
        width: 70%;
        height: 100%;
    }
}

.loader-container {
    position: relative;
    height: 100vh;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

@include responsiveOn('xs') {
    .meeting-container {
        flex-direction: column-reverse;
        overflow-y: auto;
    }

    .video-speech-container,
    .meeting-info-container {
        width: 100% !important;
    }
}
