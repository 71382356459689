@import '/src/theme/media';

.speech-timeline-container {
    height: 50%;
    overflow-y: auto;
    padding-bottom: 5rem;
}


@include responsiveOn('xs') {
    .speech-timeline-container {
        display: none;
    }
}