@import '/src/theme/variables';

div.spinner div {
    animation: fade 1s linear infinite;
}

@keyframes fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.25;
    }
}

div.spinner div.bar1 {
    transform: rotate(0deg) translate(0, -130%);
    animation-delay: 0s;
}

div.spinner div.bar2 {
    transform: rotate(45deg) translate(0, -130%);
    animation-delay: -0.9167s;
}

div.spinner div.bar3 {
    transform: rotate(90deg) translate(0, -130%);
    animation-delay: -0.833s;
}
div.spinner div.bar4 {
    transform: rotate(135deg) translate(0, -130%);
    animation-delay: -0.7497s;
}
div.spinner div.bar5 {
    transform: rotate(180deg) translate(0, -130%);
    animation-delay: -0.667s;
}
div.spinner div.bar6 {
    transform: rotate(225deg) translate(0, -130%);
    animation-delay: -0.5837s;
}
div.spinner div.bar7 {
    transform: rotate(270deg) translate(0, -130%);
    animation-delay: -0.5s;
}
div.spinner div.bar8 {
    transform: rotate(315deg) translate(0, -130%);
    animation-delay: -0.4167s;
}
