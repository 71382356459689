@import '/src/theme/media';

.summary {
    padding: 1rem;
    direction: ltr;

    &-text {
        line-height: 1.5;
        font-size: 1.7rem;
    }
}

.meeting-summary {
    padding: 2rem;
    padding-bottom: 12rem;
}

.meeting-overview-heading,
.action-items-heading,
.attendees-heading,
.discussion-points-heading {
    font-size: 2.2rem;
    font-weight: bold;
    margin: 1rem 0;
    margin-right: 1rem;
    margin-top: 2rem;
}

.discussion-points-list {
    list-style: disc;
    padding-left: 2rem;
    margin-right: 5rem;
}

.discussion-points-item {
    margin: 1rem 0;
}

.action-items-list,
.attendees-list,
.meeting-overview-content {
    margin-inline-start: 3rem;
}
