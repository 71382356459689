@import '/src/theme/variables';
@import '/src/theme/media';

.content-container {
    min-height: 7.4rem;
    display: flex;
    flex-direction: column;
}

.transcript-item {
    display: flex;
    align-items: flex-start;
    padding: 2rem;
    margin: 3rem 1rem;
    gap: 0.8rem;
    border-radius: 1rem;
    width: 100%;

    .initials-time-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content {
        margin: 0;
        margin-top: 2rem;

        span {
            transition: all 0.1s ease-in-out;
            cursor: pointer;

            &.active {
                background-color: $tertiary;
            }

            &:hover {
                background-color: rgba($blue, 0.4);
            }
        }
    }

    .initials {
        display: block;
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        background-color: $tertiary;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-weight: bold;
        font-size: 1.6rem;
    }

    .time-offset {
        margin-top: 0.6rem;
        font-size: 1.3rem;
        color: #777;
    }

    .content {
        font-size: 1.8rem;
        margin-top: 0;
        color: #555;
    }
}

.actions-container {
    left: 2%;
    top: 5%;

    svg {
        fill: transparent;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        width: 3rem;

        &.play-btn {
            svg {
                path {
                    stroke: $white;
                }
            }
        }
    }
}
