@font-face {
    font-family: 'AmpleSoftPro';
    src: url('../assets/fonts/AmpleSoftPro-Bold.ttf') format('ttf'), url('../assets/fonts/AmpleSoftPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'AmpleSoftPro';
    src: url('../assets/fonts/AmpleSoftPro-Medium.ttf') format('ttf'), url('../assets/fonts/AmpleSoftPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AmpleSoftPro';
    src: url('../assets/fonts/AmpleSoftPro-Regular.ttf') format('ttf'), url('../assets/fonts/AmpleSoftPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'AmpleSoftPro';
    src: url('../assets/fonts/AmpleSoftPro-Light.ttf') format('ttf'), url('../assets/fonts/AmpleSoftPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'AmpleSoftPro';
    src: url('../assets/fonts/AmpleSoftPro-ExtraLight.ttf') format('ttf'), url('../assets/fonts/AmpleSoftPro-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'AmpleSoftPro';
    src: url('../assets/fonts/AmpleSoftPro-Thin.ttf') format('ttf'), url('../assets/fonts/AmpleSoftPro-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

