@import '/src/theme/variables';
@import '/src/theme/media';

.timeline-item {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 2rem 0;
    direction: rtl;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}

.timeline-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-initials {
    height: 2.8rem;
    width: 2.8rem;
    background-color: $tertiary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 600;
    border-radius: 50%;
    color: $white;
    margin-inline-end: 1rem;
    font-family: AmpleSoftPro;
}

.speech-percentage {
    display: flex;
    gap: 0.5rem;
    color: rgba(0, 0, 0, 0.6);
}

.timeline-container {
    position: relative;
    width: 94.5%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 1rem;
    height: 0.6rem;
}

.timeline-placeholder {
    display: flex;
    position: absolute;
    top: 50%;
    left: 0;
    height: 0.6rem;
    border-radius: 2rem;
    background-color: $grey;
    width: 100%;
}

.timeline-slot {
    display: flex;
    position: absolute;
    top: 50%;
    height: 0.6rem;
    border-radius: 2rem;
    background-color: $primary;
    transition: all 0.1s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: $primary
    }
}

